import React from 'react'

const Feedback = ({image, link, name, role, children}) => {
    return (
    <div className='max-w-[1240px] w-full mx-auto pl-5 pr-5'>
         <div className="justify-start items-start flex border-white pb-5">
            <img className="rounded-full w-[50px] h-[50px]" src={image} alt="/" />
            <div className="pl-2 grid text-left">
                <a href={link}>
                    <span className='font-bold border-white border-b-2 text-white' text-white>{name}</span>
                </a>
                <span className="text-[#00df9a] text-sm">{role}</span>
            </div>
        </div> 
        <div>
            <span className="text-white text-center md:text-base text-sm">
                {children}
            </span>
        </div>
    </div>
    )
}

export default Feedback