import React from 'react'

const Section = ({color, fullpage, title, children}) => {
  return (
    <div className={`w-full mx-auto justify-center bg-${color} py-16 px-4 ${fullpage ? 'md:h-screen' : ''} grid`}>
       <h2 className="mb-2 text-xl font-black tracking-widest text-[#00df9a] print:font-normal uppercase pb-4">{title}</h2>
      {children}
    </div>
  )
}

export default Section