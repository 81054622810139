import React, { useState } from "react";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import resume from "../assets/andreindreiescv.pdf"

const NavBar = () => {
  const [nav, setNav] = useState(true);

  const handleNav = () => {
    setNav(!nav);
  };

  return (
    <div className="flex justify-between items-center h-24 max-w-[1240px] mx-auto px-4 text-white">
      <div className="hiidden md:fixed top-0 left-0 h-screen w-48 opacity-0 text-white flex flex-col justify-center items-start p-4">
        {/* Button 1 */}
        <button className="mb-4 w-full py-2 px-4 bg-blue-500 opacity-100 hover:bg-blue-700 rounded">
          Button 1
        </button>

        {/* Button 2 */}
        <button className="mb-4 w-full py-2 px-4 bg-green-500 opacity-100 hover:bg-green-700 rounded">
          Button 2
        </button>

        {/* Button 3 */}
        <button className="w-full py-2 px-4 bg-red-500 opacity-100 hover:bg-red-700 rounded">
          Button 3
        </button>
      </div>
      <h1 className="w-full text-3xl font-bold  text-[#00df9a] m-4">Resume</h1>
      <ul className="hidden md:flex inset-y-0 left-0 w-40 min-w-40 sticky">
        <li onClick={() => window.location.replace("/#about")} className="p-4">About me</li>
        <li onClick={() => window.location.replace("/#experience")} className="p-4">My Experience</li>
        <li onClick={() => window.location.replace("/#recommendations")} className="p-4">See who recomands me</li>
        <li className="p-4"><a href={resume} download="Download resume" target='_blank' rel="noreferrer">Download resume</a></li>
      </ul>
      <div onClick={handleNav} className="block md:hidden">
        {!nav ? <AiOutlineClose size={20} /> : <AiOutlineMenu size={20} />}
      </div>
      <div
        className={
          !nav
            ? "fixed left-0 top-0 w-[60%] h-full border-r border-r-gray-900 bg-[#000300] ease-in-out duration-500"
            : "fixed left-[-100%]"
        }
      >
        <h1 className="w-full text-3xl font-bold  text-[#00df9a] m-4">
          Resume
        </h1>

        <ul className="uppercase p-4">
          <li onClick={() => {
            handleNav();
            window.location.replace("/#about");
          }} className="p-4 border-b border-gray-600">About me</li>
          <li onClick={() => {
            handleNav();
            window.location.replace("/#experience");
          }} className="p-4 border-b border-gray-600">My Experience</li>
          <li onClick={() => {
            handleNav();
            window.location.replace("/#recommendations");
          }} className="p-4 border-b border-gray-600">See who recomands me</li>

          <li onClick={handleNav} className="p-4 border-b border-gray-600">
            <a href={resume} download="Download resume" target='_blank' rel="noreferrer">Download resume</a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default NavBar;
