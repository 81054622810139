import React, { useEffect } from "react";
import Section from "./Section";
import AOS from "aos";
import "aos/dist/aos.css";
import { ReactTyped} from "react-typed";

const Experience = () => {
  useEffect(() => {
    AOS.init();
  });

  return (
    <Section>
      <div className="max-w-[1240px] mx-auto justify-center grid md:grid-cols-2 py-2" id="experience">
        <section className="border-b-4 border-gray-300 pr-4">
          <section className="break-inside-avoid">
            <h2 className="mb-2 text-xl font-black tracking-widest text-[#00df9a] print:font-normal">
              EXPERIENCE
            </h2>
            <section data-aos="fade-right" data-aos-duration="500" className="mb-2 border-b-2 border-gray-300 break-inside-avoid">
              <header>
                <h3 className="font-semibold text-white text-md leading-snugish">
                  Software Backend Engineer @ Pitechplus
                </h3>
                <p className="text-sm leading-normal text-gray-400">
                  May 2022 &ndash; Present | Full-remote
                </p>
              </header>
              <ul className="pl-3 mt-2 font-normal text-white text-md leading-snugish">
                <li className=" text-[#00df9a]">Mission description:</li>
                <li>
                  <span className="transform -translate-y-px select-none pr-1">
                    &rsaquo;
                  </span>
                  To refactor and re-platform the application from laravel to
                  Kotlin microservices.
                </li>
                <li>
                  <span className="transform -translate-y-px select-none pr-1">
                    &rsaquo;
                  </span>
                  Performance improvements on the old platform (e.g. refactoring
                  old sync approaches to multiple async processes) also macro
                  performance improvements
                </li>
                <li>
                  <span className="transform -translate-y-px select-none pr-1">
                    &rsaquo;
                  </span>
                  Security fixes on the old platform
                </li>
                <li>
                  <span className="transform -translate-y-px select-none pr-1">
                    &rsaquo;
                  </span>
                  Having meetings with other team members and brainstorming
                  about technical solutions
                </li>
                <li>
                  <span className="transform -translate-y-px select-none pr-1">
                    &rsaquo;
                  </span>
                  Coaching other team members
                </li>
                <li>
                  <span className="transform -translate-y-px select-none pr-1">
                    &rsaquo;
                  </span>
                  Bugfixes and working on new features
                </li>
                <li>
                  <span className="transform -translate-y-px select-none pr-1">
                    &rsaquo;
                  </span>
                  Documenting parts of the platform
                </li>
                <li>
                  <span className="transform -translate-y-px select-none pr-1">
                    &rsaquo;
                  </span>
                  Helping other teams
                </li>
                <li>
                  <span className="transform -translate-y-px select-none pr-1">
                    &rsaquo;
                  </span>
                  Working with large and sensitive volumes of data
                </li>
                <li>
                  <span className="transform -translate-y-px select-none pr-1">
                    &rsaquo;
                  </span>
                  Working in both kanban/agile depends on the projects phase
                </li>
                <li className="pt-1  text-[#00df9a]">Aquired Skills:</li>
                <li>
                  <span className="transform -translate-y-px select-none pr-1">
                    &rsaquo;
                  </span>
                  Being able to identify bad habits and anti-patterns used in
                  the development process that are affecting the performance and
                  directly the user experience .
                </li>
                <li>
                  <span className="transform -translate-y-px select-none pr-1">
                    &rsaquo;
                  </span>
                  Problem solving-skills
                </li>
                <li>
                  <span className="transform -translate-y-px select-none pr-1">
                    &rsaquo;
                  </span>
                  Taking technical decisions in crucial scenarios
                </li>
                <li className="pt-1  text-[#00df9a]">Stack:</li>
                <li className="font-semibold">
                  Elasticsearch, MariaDB & Postgres gRPC communication, PHP &
                  Laravel, SQS queues, SNS events, Kotlin & spring boot , Kafka
                  & Debeziumn, Datadog monitoring, AWS services, Helm,
                  Terraform, GIT
                </li>
              </ul>
            </section>
            <section data-aos="fade-right" data-aos-duration="500" className="mb-2 border-b-2 border-gray-300 break-inside-avoid">
              <header>
                <h3 className="font-semibold text-white text-md leading-snugish">
                  Magento 2 Backend Developer | Team and Tech Leader @
                  PitechPlus
                </h3>
                <p className="text-sm leading-normal text-gray-400">
                  Sep 2018 &ndash; Apr 2022 | Hybrid in Cluj-Napoca, Romania
                </p>
              </header>
              <ul className="pl-3 mt-2 font-normal text-white text-md leading-snugish">
                <li className="text-[#00df9a]">Mission descrption:</li>
                <li>
                  <span className="transform -translate-y-px select-none pr-1">
                    &rsaquo;
                  </span>
                  Working with the latest Magento 2 versions.
                </li>
                <li>
                  <span className="transform -translate-y-px select-none pr-1">
                    &rsaquo;
                  </span>
                  Applied security patches and maintained and expanded a Magento
                  2 Community fast-growing online shop.
                </li>
                <li>
                  <span className="transform -translate-y-px select-none pr-1">
                    &rsaquo;
                  </span>
                  Build efficient, testable, and reusable extensions of Magento
                  2.
                </li>
                <li>
                  <span className="transform -translate-y-px select-none pr-1">
                    &rsaquo;
                  </span>
                  Resolve cross-browser compatibility issues and write clean,
                  structured, and well-documented code.
                </li>
                <li>
                  <span className="transform -translate-y-px select-none pr-1">
                    &rsaquo;
                  </span>
                  Work with Docker development environment and version control
                  using Git.
                </li>
                <li>
                  <span className="transform -translate-y-px select-none pr-1">
                    &rsaquo;
                  </span>
                  Creating and managing API integrations, as well as creating
                  unit and functional/integration tests.
                </li>
                <li className="pt-1 text-[#00df9a]">Additional Info:</li>
                <li>
                  <span className="transform -translate-y-px select-none pr-1">
                    &rsaquo;
                  </span>
                  Upgraded Magento project version 2.2.1 to 2.4.3 with a new
                  Docker setup and Kubernetes infrastructure while maintaining
                  Magento's best practices.
                </li>
                <li>
                  <span className="transform -translate-y-px select-none pr-1">
                    &rsaquo;
                  </span>
                  Integrated SagePay module for payment and extended it to fit
                  the project's requirements, as well as working with Gigya,
                  Mandrill, and SamlSSO modules.
                </li>
                <li>
                  <span className="transform -translate-y-px select-none pr-1">
                    &rsaquo;
                  </span>
                  Occasionally worked with Symfony/Laravel, Kotlin with Spring
                  Boot, and React for frontend development.
                </li>
                <li className="pt-1 text-[#00df9a]">Technical Background:</li>
                <li>
                  <span className="transform -translate-y-px select-none pr-1">
                    &rsaquo;
                  </span>
                  Configured Kubernetes infrastructure with Nginx, Varnish,
                  Redis, Elasticsearch 7, RabbitMQ, PHP 7.4, and various other
                  projects.
                </li>
                <li>
                  <span className="transform -translate-y-px select-none pr-1">
                    &rsaquo;
                  </span>
                  Built complex and efficient Docker setups for various
                  projects, converting Vagrant setups to Docker setups.
                </li>
                <li className="pt-1 text-[#00df9a]">Daily Responsibilities:</li>
                <li>
                  <span className="transform -translate-y-px select-none pr-1">
                    &rsaquo;
                  </span>
                  Taking technical decisions and conducting code peer reviews
                  with other team members.
                </li>
                <li>
                  <span className="transform -translate-y-px select-none pr-1">
                    &rsaquo;
                  </span>
                  Participating in daily stand-up meetings led by the client's
                  PM and participating in product demos.
                </li>
                <li>
                  <span class="transform -translate-y-px select-none pr-1">
                    &rsaquo;
                  </span>
                  Documenting code changes and being a trainer in internship
                  sessions.
                </li>
                <li>
                  <span class="transform -translate-y-px select-none pr-1">
                    &rsaquo;
                  </span>
                  Participating in hiring interviews and occasionally offering
                  24/7 support on call.
                </li>
                <li className="pt-1  text-[#00df9a]">Stack:</li>
                <li className="font-semibold">
                  Magento 2, Php, Kubernetes, nginx, Varnish, Docker, GCP
                  services, knockout js, jquery, GIT, CI/CD
                </li>
              </ul>
            </section>
            <section data-aos="fade-right" data-aos-duration="500" className="mb-2 border-b-2 border-gray-300 break-inside-avoid">
              <header>
                <h3 className="font-semibold text-white text-md leading-snugish">
                  Full-Stack Software Developer @ Pentalog
                </h3>
                <p className="text-sm leading-normal text-gray-400">
                  Nov 2017 – Aug 2018 | on-site
                </p>
              </header>
              <ul className="pl-3 mt-2 font-normal text-white text-md leading-snugish">
                <li className="text-[#00df9a]">Mission description:</li>
                <li>
                  <span className="transform -translate-y-px select-none pr-1">
                    &rsaquo;
                  </span>
                  Project maintenance and bug fixing, including fixing issues
                  assigned by the tester.
                </li>
                <li>
                  <span className="transform -translate-y-px select-none pr-1">
                    &rsaquo;
                  </span>
                  Developed new features according to client specifications.
                </li>
                <li>
                  <span className="transform -translate-y-px select-none pr-1">
                    &rsaquo;
                  </span>
                  Tested tasks to ensure quality and functionality.
                </li>
                <li className="pt-1 text-[#00df9a]">Stack:</li>
                <li className="font-semibold">
                  PHP, Laravel, JavaScript, HTML, CSS/SASS, AJAX, jQuery, MySQL,
                  GIT, Agile, Kanban, Linux
                </li>
              </ul>
            </section>
            <section data-aos="fade-right" data-aos-duration="500" className="mb-2 break-inside-avoid">
              <header>
                <h3 className="font-semibold text-white text-md leading-snugish">
                  Intern @ Pentalog
                </h3>
                <p className="text-sm leading-normal text-gray-400">
                  Aug 2017 – Oct 2017 | on-site
                </p>
              </header>
              <ul className="pl-3 mt-2 font-normal text-white text-md leading-snugish">
                <li className="text-[#00df9a]">Mission Description:</li>
                <li>
                  <span className="transform -translate-y-px select-none pr-1">
                    &rsaquo;
                  </span>
                  Trainee within Pentalog group.
                </li>
                <li>
                  <span className="transform -translate-y-px select-none pr-1">
                    &rsaquo;
                  </span>
                  Participated in an internship program on PHP and web
                  technologies.
                </li>
                <li>
                  <span className="transform -translate-y-px select-none pr-1">
                    &rsaquo;
                  </span>
                  Extensive study of PHP development technologies, concepts, and
                  principles, including:
                  <ul className="pl-3 mt-1">
                    <li>
                      - PHP basics: Language and Platform; Data Types;
                      Variables, Constants; Operators; Control Structures;
                      Errors and Error Management; Functions and Constructs; Web
                      Programming; File Manipulation, Streams, Network; PHP
                      Sessions; PHP and Databases; PHP Security
                    </li>
                    <li>
                      - OOP General: Class Methods and Properties; Constants,
                      Static Methods and Properties; Interfaces and Abstract
                      Classes; Exceptions; Design Patterns
                    </li>
                    <li>- XML and Web Services: DOM, XSL</li>
                    <li>
                      - Web basics: HTML, HTML5, CSS, jQuery/jQuery Mobile,
                      JavaScript
                    </li>
                    <li>
                      - SQL and MySQL: Create/Drop, Select/Joins,
                      Insert/Update/Delete, Indexes/Column Types, Views,
                      Transactions, Stored Procedures, Functions, Database
                      Theory
                    </li>
                    <li>- Symfony3</li>
                    <li>- Design Patterns</li>
                    <li>- Unit testing in PHP</li>
                  </ul>
                </li>
                <li>
                  <span className="transform -translate-y-px select-none pr-1">
                    &rsaquo;
                  </span>
                  Applied the acquired knowledge by:
                  <ul className="pl-3 mt-1">
                    <li>
                      - Developing a simple API in Symfony using ORM (Doctrine)
                    </li>
                    <li>- Creating and using web services</li>
                    <li>
                      - Creating a UI that displays products and their
                      characteristics; the UI was connected to a PHP server
                    </li>
                  </ul>
                </li>
                <li className="pt-1 text-[#00df9a]">Stack:</li>
                <li className="font-semibold">
                  PHP, Symfony, OOP, XML, DOM, XSL, HTML, HTML5, CSS, jQuery,
                  SQL, MySQL, Doctrine, Design Patterns, Unit Testing
                </li>
              </ul>
            </section>
          </section>
        </section>
        <section className="pb-0 mt-2 border-b-4 border-gray-300 first:mt-0 break-inside-avoid md:pl-4">
          <section data-aos="fade-left" data-aos-duration="500" className="pb-4 mt-4 mb-4 border-gray-300 break-inside-avoid border-b-4">
            <header>
              <h3 className="font-semibold text-white text-md leading-snugish">
                DevOps @ PitechPlus
              </h3>
              <p className="text-sm leading-normal text-gray-400">
                Sep 2018 – Present | Extra-mile | Secondary sets of skills
              </p>
            </header>
            <ul className="pl-3 mt-2 font-normal text-white text-md leading-snugish">
              <li className="text-[#00df9a]">
                Considering my devops skill only as an extra mile (and not as a
                dedicated position) for my back-end background, this what I have
                made so far:
              </li>
              <li>
                <span className="transform -translate-y-px select-none pr-1">
                  &rsaquo;
                </span>
                Working with AWS and Kubernetes clusters such as AWS EKS and EC2
                using Kops.
              </li>
              <li>
                <span className="transform -translate-y-px select-none pr-1">
                  &rsaquo;
                </span>
                Setting up Continuous Integration and Delivery, as well as cloud
                infrastructure for projects based on Magento, Symfony, and Java.
              </li>
              <li>
                <span className="transform -translate-y-px select-none pr-1">
                  &rsaquo;
                </span>
                Fine-tuning existing CI/CD pipelines to increase efficiency and
                speed.
              </li>
              <li>
                <span className="transform -translate-y-px select-none pr-1">
                  &rsaquo;
                </span>
                Setting up local environments infrastructure for Magento,
                Symfony, and Java projects using Docker, Minikube, and Vagrant.
              </li>
              <li>
                <span className="transform -translate-y-px select-none pr-1">
                  &rsaquo;
                </span>
                Working with Helm to build infrastructure from scratch for
                various project types.
              </li>
              <li>
                <span className="transform -translate-y-px select-none pr-1">
                  &rsaquo;
                </span>
                Using Terraform to provision various AWS services and resources.
              </li>
              <li>
                <span className="transform -translate-y-px select-none pr-1">
                  &rsaquo;
                </span>
                Creating playbooks for Ansible to automate EC2 instances, such
                as updating security patches and installing required development
                libraries.
              </li>
              <li className="pt-1 text-[#00df9a]">AWS Expertise:</li>
              <li>
                <span className="transform -translate-y-px select-none pr-1">
                  &rsaquo;
                </span>
                Experienced in AWS technologies such as EC2 (including creating
                custom AMIs), EKS, ECS, ELB (mostly ALB), Lambda, S3, Athena,
                RDS, and Aurora.
              </li>
            </ul>
          </section>

          <section className="break-inside-avoid">
            <h2 className="mb-2 text-lg font-bold tracking-widest text-[#00df9a] print:font-normal">
              EDUCATION
            </h2>
            <section data-aos="fade-left" data-aos-duration="500" className="mt-2 border-b-2 break-inside-avoid">
              <header>
                <h3 className="text-lg font-semibold text-white leading-snugish">
                  Technical University of Cluj-Napoca
                </h3>
                <p className="leading-normal text-gray-400 text-md">
                  2016 &ndash; 2022
                </p>
              </header>
              <ul className="mt-2 list-disc list-inside text-white text-md">
                <li>
                  <span className="font-semibold text-md">
                    Bachelor's degree in:{" "}
                  </span>
                  Electrical Engineering
                </li>
                <li>
                  <span className="font-semibold text-md">
                    Specialization:{" "}
                  </span>
                  Electrotechnics
                </li>
              </ul>
            </section>
            <section data-aos="fade-left" data-aos-duration="500" className="pb-4 mt-4 mb-4 break-inside-avoid border-b-4">
              <header>
                <h3 className="flex-grow text-lg font-semibold text-white leading-snugish">
                  Theoretical High School "Ion Agârbiceanu" Jibou
                </h3>
                <p className="leading-normal text-gray-400 text-md">
                  2012 &ndash; 2016
                </p>
              </header>
              <ul className="mt-2 list-disc list-inside text-white text-md">
                <li>
                  <span className="font-semibold text-md">Profile: </span>
                  Mathematics-Informatics
                </li>
              </ul>
            </section>
          </section>
          <section className="break-inside-avoid">
            <h2 className="mb-2 text-lg font-bold tracking-widest text-[#00df9a] print:font-normal">
              CERTIFICATIONS
            </h2>
            <section data-aos="fade-left" data-aos-duration="500" className="pb-4 mt-4 mb-4 break-inside-avoid">
              <div role="status" className="flex">
                <label className="text-white md:text-xl font-bold md:pl-4 pl-2">In progress<ReactTyped className='' strings={['...']} typeSpeed={120} backSpeed={120} loop/></label>
              </div>
            </section>
          </section>
        </section>
      </div>
    </Section>
  );
};

export default Experience;
