import React from "react";
import NavBar from "./components/NavBar";
import Hero from "./components/Hero";
import AboutMe from "./components/AboutMe";
import Experience from "./components/Experience";
import Recomandations from "./components/Recomandations";

function App() {
  return (
    <div className="App">
      <NavBar/>
      <Hero/>
      <AboutMe/>
      <Experience/>
      <Recomandations/>
    </div>
  );
}

export default App;
