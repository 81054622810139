import React, { useState, useEffect } from 'react'
import Section from './Section'
import Feedback from './Feedback';
import Arnold from "../assets/arnold.jpg"
import ReactSimplyCarousel from 'react-simply-carousel';
import AOS from "aos";
import "aos/dist/aos.css";

const Recomandations = () => {
    const [activeSlideIndex, setActiveSlideIndex] = useState(0);

    useEffect(() => {
        AOS.init();
    });

    return (
        <Section color='[#000300]' title='Recommendations'>
            <section className="pr-4" id="recommendations">
                <section className="break-inside-avoid">
                    <div data-aos="fade-up" data-aos-duration="200">
                        <ReactSimplyCarousel
                            activeSlideIndex={activeSlideIndex}
                            onRequestChange={setActiveSlideIndex}
                            itemsToShow={1}
                            itemsToScroll={2}
                            autoplay={true}
                            responsiveProps={[
                                {
                                    itemsToShow: 1,
                                    itemsToScroll: 1,
                                    minWidth: 400
                                },
                            ]}
                            speed={400}
                            easing="linear"
                        >
                            <div className='min-w-[400px] sm:min-w-[768px] md:min-w-[1240px]'>
                                <Feedback
                                        image={Arnold}
                                        name='Arnold Peter'
                                        link='https://www.linkedin.com/in/arnold-peter-8a222858/'
                                        role='Senior Software Developer | Team leader at PitechPlus'
                                    >
                                        It is my pleasure to recommend Andrei based on our collaboration on a significant German HR project, where I had the privilege of serving as his team lead. Throughout our time working together, Andrei consistently impressed me with his exceptional talent, dedication, and strong technical skills in both PHP and Kotlin, coupled with a solid understanding of infrastructure.
                                        Andrei's contributions were instrumental in the success of our project, solving complex issues with ElasticSearch, delivering exceptional quality code and bringing new ideas all the time.
                                        What truly set Andrei apart was his unwavering dedication to the project. He approached every task with a meticulous attention to detail, ensuring that our solutions were not only functional but also scalable and maintainable in the long term.
                                        In addition to his technical prowess, Andrei demonstrated a keen interest in infrastructure, showcasing a willingness to learn and adapt to new technologies and methodologies.
                                        Moreover, Andrei's positive attitude and collaborative spirit greatly enriched our team dynamic. He consistently fostered a supportive environment, where ideas were freely exchanged, and everyone felt valued and empowered to contribute their best work. His professionalism, reliability, and integrity made him a trusted colleague and a pleasure to work with.
                                        In conclusion, I wholeheartedly endorse Andrei for any role that requires talent, dedication, and strong technical skills in PHP, Kotlin, and infrastructure. He is a dedicated professional who consistently delivers exceptional results, and I have no doubt that he will excel in any future endeavors. It was a privilege to work alongside him, and I am confident that he will make a valuable addition to any team or organization.
                                </Feedback>
                            </div>
                            {/* <div className='min-w-[400px] sm:min-w-[768px] md:min-w-[1240px]'>
                                <Feedback
                                        image={Arnold}
                                        name='Anca Muresan'
                                        link='https://www.linkedin.com/in/arnold-peter-8a222858/'
                                        role='Senior Software Developer | Team leader at PitechPlus'
                                    >
                                        It is my pleasure to recommend Andrei based on our collaboration on a significant German HR project, where I had the privilege of serving as his team lead. Throughout our time working together, Andrei consistently impressed me with his exceptional talent, dedication, and strong technical skills in both PHP and Kotlin, coupled with a solid understanding of infrastructure.
                                        Andrei's contributions were instrumental in the success of our project, solving complex issues with ElasticSearch, delivering exceptional quality code and bringing new ideas all the time.
                                        What truly set Andrei apart was his unwavering dedication to the project. He approached every task with a meticulous attention to detail, ensuring that our solutions were not only functional but also scalable and maintainable in the long term.
                                        In addition to his technical prowess, Andrei demonstrated a keen interest in infrastructure, showcasing a willingness to learn and adapt to new technologies and methodologies.
                                        Moreover, Andrei's positive attitude and collaborative spirit greatly enriched our team dynamic. He consistently fostered a supportive environment, where ideas were freely exchanged, and everyone felt valued and empowered to contribute their best work. His professionalism, reliability, and integrity made him a trusted colleague and a pleasure to work with.
                                        In conclusion, I wholeheartedly endorse Andrei for any role that requires talent, dedication, and strong technical skills in PHP, Kotlin, and infrastructure. He is a dedicated professional who consistently delivers exceptional results, and I have no doubt that he will excel in any future endeavors. It was a privilege to work alongside him, and I am confident that he will make a valuable addition to any team or organization.
                                </Feedback>
                            </div> */}
                        </ReactSimplyCarousel>
                    </div>
                </section>
            </section>
        </Section>
    )
}

export default Recomandations