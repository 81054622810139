import React, { useEffect, useState } from 'react';
import { ReactTyped } from "react-typed";
import Profile from "../assets/profile.jpg"
import { FaLinkedin, FaFacebook, FaInstagram, FaGithub } from "react-icons/fa";

const Hero = () => {
    const [loaded, setLoaded] = useState(false);

    const handleLoad = () => {
        setLoaded(true);
    }

    useEffect(() => {
        handleLoad()
    })

    return (
        <div className='text-white'>
            <div className='max-w-[800px] mt-[-96px] w-full h-screen mx-auto text-center flex flex-col justify-center'>
                <div className={`justify-center items-center flex border-white border-b-2 pb-4 transition-opacity ease-in duration-[1200ms] ${loaded ? "opacity-100" : "opacity-0"} `}>
                    <img className="rounded-full w-96 h-96" src={Profile} alt="/" />
                </div>
                <p className='text-[#00df9a] font-bold p-2'>Crafting a career that weaves together passion, precision, and proficiency.</p>
                <h1 className='md:text-7xl sm:text-6xl text-4xl font-bold md:py-6'>Andrei Indreieş</h1>
                <div className='flex justify-center items-center'>
                    <p className='md:text-3xl sm:text-2xl text-xl font-bold py-4'>Experienced Software</p>
                    <ReactTyped className='text-[#00df9a] md:text-3xl sm:text-2xl text-xl font-bold md:pl-4 pl-2' strings={['Backend', 'Devops', 'Cloud']} typeSpeed={120} backSpeed={120} loop />
                    <p className='md:text-3xl sm:text-2xl text-xl font-bold pl-2'>engineer</p>
                </div>
                <p className='text-gray-400 text-xl font-bold pl-2'>Maybe your future candidate</p>
                <div className='justify-center items-center flex border-white pt-4'>
                    <a href="https://www.linkedin.com/in/andrei-indreieș-241056147"><FaLinkedin className='pr-4' size={35} /></a>
                    <a href="https://www.facebook.com/andrei.indreies"><FaFacebook size={20} /></a>
                    <a href="https://www.instagram.com/andrei.indreies"><FaInstagram className='pl-4' size={35} /></a>
                    <a href="https://github.com/andrei-indreies"><FaGithub className='pl-4' size={35} /></a>
                </div>
            </div>
        </div>
    )
}

export default Hero
