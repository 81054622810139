import React, { useEffect } from 'react'
import Section from './Section'
import video from "../assets/aboutme-emoji.gif";
import AOS from 'aos';
import 'aos/dist/aos.css';

const AboutMe = () => {
    useEffect(() => {
        AOS.init();
    })

    return (
        <Section color='[#000300]' fullpage='true'>
            <div className='max-w-[1240px] mx-auto grid md:grid-cols-2 text-white' id="about">
                <div data-aos="zoom-in" data-aos-duration="2000">
                    <img className="md:w-[700px] w-96 md:my-4 mx-auto" src={video} alt="/" />
                </div>
                <div data-aos='fade-up' data-aos-duration="1500" className='flex flex-col justify-center items-center'>
                    <p className='text-[#00df9a] font-bold'>experience wise</p>
                    <h1 className='md:text-4xl sm:text-3xl text-2xl font-bold md:py-2 uppercase'>About me</h1>
                    <p className='lg:text-xl'>As a highly experienced backend developer with over 7 years of expertise, 
                    I am deeply committed to exploring the latest advancements in technology and continuously expanding my knowledge base.
                    I have been working mostly with php (laravel/magento) and kotlin (spring boot) for web development.
                    I worked with monolith projects and also with microservices with event driven design.
                    </p>
                    <p className='lg:pt-4 pt-2 lg:text-xl'>
                    I have also gained a strong understanding of DevOps principles and practices.
                    I have experience working with CI/CD pipelines and have utilized tools GitLab CI/CD. 
                    I am also familiar with containerization technologies such as Docker and Kubernetes and have used them to deploy and manage applications in production environments. 
                    I have a good understanding of infrastructure-as-code (IAC) and have used tools like Ansible and Terraform to automate infrastructure provisioning and management. 
                    My experience in DevOps has enabled me to collaborate effectively with other team members, improve the quality and speed of software delivery, and ensure high availability and scalability of applications in production.
                    </p>
                </div>
            </div>
            <div data-aos='zoom-out' data-aos-duration="1500" className='flex flex-col justify-center items-center text-white py-12'>
                <p className='text-[#00df9a] font-bold'>personality wise</p> 
                <p className='pt-4 pl-10 pr-10 md:text-xl'>
                Tech enthusiast with a passion for snowboarding and a love for dogs.
                </p>
            </div>
        </Section>
    )
}

export default AboutMe